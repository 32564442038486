<template>
  <div class="grid-layout" :style="'grid-template-rows:' + templateRows + ';grid-template-columns:' + templateCols">
    <div
      v-for="(item,index) in grids"
      v-show="fullRow == 0 || (item[0] === fullRow && item[1] === fullCol)"
      :key="index"
      :class="{ selected : selectIndex === index }"
      :style="calcStyle(item)"
      @click="() => onClick(index)"
    >
      <div><slot name="item" :item="{ index }" /></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Grid',
  props: {
    layout: {
      type: Array,
      default: () => [2, 2, [1, 1, 1, 1], [1, 2, 1, 1], [2, 1, 1, 1], [2, 2, 1, 1]]
    },
    allowClick: {
      type: Boolean,
      default: true
    },
    selectIndex: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      clickIndex: -1,
      fullCol: 0,
      fullRow: 0
    }
  },
  computed: {
    rows() {
      return this.layout[0]
    },
    cols() {
      return this.layout[1]
    },
    templateRows() {
      return new Array(this.rows).fill('1fr').join(' ')
    },
    templateCols() {
      return new Array(this.cols).fill('1fr').join(' ')
    },
    grids() {
      return this.layout.slice(2)
    }
  },
  methods: {
    calcStyle(item) {
      if (this.fullRow > 0 && this.fullRow === item[0] && this.fullCol === item[1]) {
        return `grid-area: 1 / 1 / span ${this.rows} / span ${this.cols}`
      }
      return `grid-area:${item[0]} / ${item[1]} / span ${item[2]} / span ${item[3]}`
    },
    full(index) {
      if (index < 0) {
        this.fullRow = 0
        this.fullCol = 0
        return
      }
      const grid = this.grids[index]
      if (this.fullRow === grid[0] && this.fullCol === grid[1]) {
        return this.full(-1)
      }
      this.fullRow = grid[0]
      this.fullCol = grid[1]
    },
    onClick(index) {
      if (this.allowClick) {
        this.$emit('update:selectIndex', this.selectIndex === index ? -1 : index)
      }
    }
  }
}
</script>

<style scoped>
.grid-layout{
    flex: 1;
    display: grid;
    border-left: 1px solid #11234c;
    border-top: 1px solid #11234c;
    grid-template-areas: none;
}
.grid-layout > div {
    background-color: rgba(0, 12, 47, 0.25);
    border-right: 1px solid #11234c;
    border-bottom: 1px solid #11234c;
}
.grid-layout > div > div{
    position: relative;
    width: 100%;
    height: 100%;
    border:2px dashed transparent;
}
.selected > div{
    border-color: orange !important;
}
</style>
